import {defineStore} from 'pinia'
import axios from "axios";

interface Group {
    cn: string;
    gidnumber: number,
    memberuid?: string[]
}

export const useUsersStore = defineStore('users', {
    state: () => ({
        users: [] as any[],
        groups: [] as Group[]
    }),
    actions: {
        fetchUsers() {
            return axios
                .get(`/portal-backend/users`)
                .then((res) => {
                    this.$state.users = res.data
                    return res.data
                })
                .catch(err => console.error(err))
        },
        createUser(user: any) {
            return axios
                .post(`/portal-backend/users`, user)
                .then((res) => {
                    this.$state.users.push(user)
                    return res.data
                })
                .catch(err => console.error(err))
        },
        getUserDetail(userId: string) {
            return axios
                .get(`/portal-backend/users/${userId}`)
                .then((res) => {
                    this.$state.users = res.data
                    console.log(res.data)
                    return res.data
                })
                .catch(err => console.error(err))
        },
        getRoles() {
            return axios
                .get(`/portal-backend/roles`)
                .then((res) => res.data)
                .catch(err => console.error(err))
        },
        getGroups() {
            return axios
                .get(`/portal-backend/groups`)
                .then((res) => {
                    this.$state.groups = res.data
                    return res.data
                })
                .catch(err => console.error(err))
        },
        newGroup(group: Group) {
            return axios
                .post(`/portal-backend/groups`, group)
                .then((res) => {
                    this.$state.groups.push(group)
                    return res.data
                })
                .catch(err => console.error(err))
        },
        updateGroup(groupId: any, userId: string) {
            return axios
                .put(`/portal-backend/groups/${groupId}`,
                    {
                        memberuid: userId
                    })
        },
        deleteUser(userId: string) {
            return axios
                .delete(`/portal-backend/users/${userId}`)
                .then((res) => res.data)
                .catch(err => console.error(err))
        },
        updateRole(userId: string, role: string) {
            return axios
                .put(`/portal-backend/roles/${role}`,
                    {
                        memberuid: userId
                    })
        }
    }
})